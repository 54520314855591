.image-gallery-image img {
    width: 100%; /* Ensure images cover the entire area */
    height: auto; /* Maintain aspect ratio */
    max-height: 500px; /* Limit the maximum height of the images */
    object-fit: cover; /* Prevent images from stretching */
  }
  
  .place-name {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0 10px;
    margin: 50px 0;
    border-radius: 5px;
    font-size: 25px;
  }
  