ul.footer-contact li a {
  text-decoration: none;
  color: white;
  font-size: 500;
  font-weight: 600;
}

.footer-icon {
  padding-right: 15px;
  color: #f0bb3a;
}

.social-icon {
  color: #fff;
}

.social-link a {
  display: inline-block;
  background-color: #f0bb3a;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  margin: 5px;
}

.footer-text {
  color: #f0bb3a;
  font-size: 20px;
  font-weight: 500;
}

a .footer-text {
  text-decoration: none !important;
}

.contact-us {
  font-size: 20px;
}


.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  /* Ensure it's on top of other content */
}

.rsc>.rsc-float-button {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
}

@media screen and (max-width:500px) {
  .rsc div.rsc-container {
    height:calc(100vh - 4rem);
    top: 0 !important;
  }
}

.custom-whatsapp {
  position: fixed;
  bottom: 10px;
  right: 120px; /* Adjust this value to shift left */
  z-index: 1000;
}