.TermsConditionsBx{
    margin-top: 50px;
    padding: 30px;
    
}
.terms-heading{
    font-size: 50px;
    background-color: antiquewhite;
    font-weight: 700;
}
.heading-point{
    font-size: 30px;
    font-weight: 500;
}