
.image-container {
    position: relative;
  }
  
  .form-overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    /* top: 50%; */
    /* left: 30%; */
    /* transform: translate(-50%, -50%); */
  }

  .form{
    background-color: rgb(80, 79, 79);
    color: #ffffff;
  }

  .form::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  .form::-webkit-scrollbar{
    width: 0px;
    background-color: #F5F5F5;
  }
  .form::-webkit-scrollbar-thumb{
    background-color: #000000;
    border: 2px solid #555555;
  }
  
  
@media (min-width: 1023px) and (max-width: 1400px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .image-container{
    /* height: 100px; */
  }
}

@media (min-width: 500px
) and (max-width: 768px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .image-container{
    /* height: 600px; */
  }
  
  
}
@media (min-width: 375px
) and (max-width: 500px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .image-container{
    /* height: 600px; */
  }

}