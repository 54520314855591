.map-container {
  position: relative;
  width: 100%;
}

.map {
  width: 100%;
  height: 400px;
}

.distance-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 10px;
  animation: fade-in 1s ease;
}

.distance {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* TrackingOrder.css */

.map-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.map {
  width: 100%;
  height: 100%;
}

.distance {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 5px;
}
