@tailwind base;
@tailwind components;
@tailwind utilities;

body{
   overflow-x: hidden !important; 
}

.scroll-to-top-button {
   right: 20px !important; /* Adjust this value to shift further right */
   bottom: 20px !important; /* Keep it at the bottom */
   background-color: #007BFF; /* Background color */
   color: white; /* Text color */
   border-radius: 50%; /* Make it circular */
   height: 50px; /* Height of the button */
   width: 50px; /* Width of the button */
   display: flex; /* Flexbox for centering content */
   align-items: center; /* Vertical centering */
   justify-content: center; /* Horizontal centering */
 }
 
 .scroll-to-top-button:hover {
   background-color: #0056b3; /* Change background color on hover */
 }
 