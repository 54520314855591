.current-data-bg {
  position: relative;
  background-image: url("../Assests/Homepageimages/slider1.jpg");
  height: 100%;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}
.current-data-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}
.current-data {
  z-index: 2;
}
/* Remove hover effect */
.button-no-hover:hover {
  background-color: inherit;
  color: inherit;
  /* No change on hover */
}
/* Remove hover effect on table rows and cells */
.no-hover-effect tr:hover,
.no-hover-effect th:hover,
.no-hover-effect td:hover {
  background-color: inherit;
  color: inherit;
  /* No change on hover */
}
/* Change text color to white on hover */
.hover-text-white tr:hover {
  color: white;
}

/* Optional: Keep the background color the same, or set to a specific color */
.hover-text-white tr:hover td {
  background-color: inherit; /* or set a specific background color */
}
