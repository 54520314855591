.contact-banner-img{
    background-image: url("../Assests/Homepageimages/slider2.jpg");
    height:40vh;
    background-position:right;
    background-repeat: no-repeat;
    background-size: cover;
  }
 
  @media (max-width: 768px){
    .contact-banner-img{
      height:20vh;
  }
  }