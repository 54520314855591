/* General Styles */



/* .ShubhankarName {
   margin-left: -200px;
    
  } */
  
  .fontstyle {
    font-family: "Cedarville Cursive", cursive;
    font-weight: 900;
    font-style: italic;
  }
  
  .foot-head {
    font-size: 20px;
  }
  
  /* Small Screens (Mobile) */
  @media (max-width: 767px) {
    .ShubhankarName {
      margin-left: 0;
      text-align: center;
    }
  
    nav {
      padding: 1rem 0;
    }
  
    ul {
      flex-direction: column;
      align-items: center;
    }
  
    /* li {
      margin: 0.5rem 0;
    } */
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  
    /* Adjust the name and slogan paragraph for better responsiveness */
    .name-slogan {
      font-size: 1.2rem;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
  
  /* Medium Screens (Tablet) */
  @media (min-width: 768px) and (max-width: 1023px) {
    .ShubhankarName {
      /* margin-left: -300px; */
      text-align: left;
    }
  
    nav {
      padding: 1rem 0;
    }
  
    ul {
      flex-direction: row;
    }
  
    /* li {
      margin: 0 1rem;
    } */
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  
  /* Small-to-Medium Screens (Phablet) */
  @media (min-width: 678px) and (max-width: 900px) {
    .ShubhankarName {
      /* margin-left: 0; */
      text-align: center;
    }
  
    .name-slogan {
      font-size: 1.2rem;
      margin-top: 0.5rem;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  
  /* Large Screens (Laptop) */
  @media (min-width: 1024px) and (max-width: 1279px) {
    .ShubhankarName {
      /* margin-left: -250px; */
    }
  
    nav {
      padding: 1rem 0;
    }
  
    ul {
      flex-direction: row;
    }
  
    /* li {
      margin: 0 1rem;
    } */
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  
  /* Extra Large Screens (Desktop) */
  @media (min-width: 1280px) {
    .ShubhankarName {
      /* margin-left: -350px; */
    }
  
    nav {
      padding: 1rem 0;
    }
  
    ul {
      flex-direction: row;
    }
  
    /* li {
      margin: 0 1rem;
    } */
  
    button {
      padding: 0.5rem 1rem;
      font-size: 1rem;
    }
  }
  /* Small Screens (Mobile) */
@media (max-width: 767px) {
  .ShubhankarName {
    /* margin-left: 0; */
    text-align: center;
  }
  
  nav {
    padding: 0.5rem 0;
  }
  
  ul {
    flex-direction: column;
    align-items: center;
  }
  
  /* li {
    margin: 0.3rem 0;
  } */
  
  button {
    padding: 0.5rem 0.8rem;
    font-size: 0.9rem;
  }
}

/* Medium Screens (Tablet) */
@media (min-width: 768px) and (max-width: 1023px) {
  .ShubhankarName {
    /* margin-left: -150px; Adjust as needed */
    text-align: left;
  }
  
  nav {
    padding: 0.8rem 0;
  }
  
  ul {
    flex-direction: row;
  }
  
  /* li {
    margin: 0 0.8rem;
  } */
  
  button {
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
  }
}
/* For screens between 576px and 767.98px (small devices), apply these styles */
@media (min-width: 1023px) and (max-width: 1300px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .ShubhankarName{
    margin-left: 10px;
  }
}

@media (min-width: 768px
) and (max-width: 900px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .ShubhankarName{
    margin-left: 10px;
  }
  
}