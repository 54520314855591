.table-responsive {
    overflow-x: auto; /* enable horizontal scrolling */
    max-width: 100%; /* ensure the table doesn't exceed the container width */
  }
  
  .table {
    width: 100%; /* make the table full-width */
    border-collapse: collapse; /* remove extra spacing between cells */
  }
  
  /* Increase font size and padding for th and td */
  .table th, .table td {
    padding: 1rem; /* increase cell padding */
  }
  /* .table th{
    background-color: black;
    color: white;
  } */
  
  /* Increase font size for thead and tbody */
  /* .table thead, .table tbody {
    font-size: 1.1rem;
  } */
  
  h2 {
    text-align: center;
    /* color: #007bff; */
  }

  
  .table {
    margin-top: 10px;
  }
  
  .form-control, .form-select, .btn {
    margin-bottom: 10px;
  }
  