.location-converter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width:300px;
  }
  /* CSS for Bootstrap grid system */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Example additional styling */
.left-side {
  background-color: #f0f0f0;
  padding: 20px;
}

.right-side {
  background-color: #e0e0e0;
  padding: 20px;
}

  .input-container {
    margin-bottom: 10px;
    position:relative;
    width:100%;
  }
  
  input {
    padding: 5px;
    margin:0;
  }
  
  
  .convert-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .convert-button:hover {
    background-color: #0056b3;
  }
  .distance-checker {
    text-align: right;
    margin-bottom: 20px;
    margin-top: -15px; /* Moves it higher */
    animation: fadeInUp 1s ease-out; /* Adds motion */
  }
  
  .distance-checker-text {
    font-weight: 900; /* Extra bold */
    font-size: 1.0rem;
    color: #26517e; /* Primary color */
    letter-spacing: 1px; /* Spacing between letters */
    text-transform: uppercase; /* Capitalizes the text */
  }
  
  /* Keyframes for the fade-in-up animation */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px); /* Starts lower */
    }
    to {
      opacity: 1;
      transform: translateY(0); /* Ends at original position */
    }
  }
  
  .map-section{
    margin-top: 20px;
  }
  .map-section iframe{
    width: 100%;
    height: 450px;
    /* border: 2px solid black; */
  }
  #startLocation,
  #endLocation{
    border: 2px solid black;
  }
  /* .location-converter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }

  
  /* input {
    padding: 5px;
    margin-left: 5px;
  } */
  
  /* .convert-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .convert-button:hover {
    background-color: #0056b3;
  } */
  
  @media screen and (max-width: 768px) {
    .col-lg-6 {
      flex: 0 0 100%;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media screen and (max-width: 576px) {
    .convert-button {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  