/* .home-banner-section {
    background-image: url("../Assests/Homepageimages/slider1.jpg");
    background-attachment: fixed;
    height: 100vh;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 768px){
    .home-banner-section {
      height:auto;
      padding-bottom: 20px;
  }
  } */.home-banner-section {
  position: relative;
}

.carousel-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.carousel-container .slick-slider {
  height: 100%;
}

.carousel-container .slick-slider div {
  height: 100%;
}

.home-banner-section .grid {
  position: relative;
  z-index: 1; /* Ensure content appears above the carousel */
}



  .home-banner-section {
    position: relative;
    /* background-image: url("../Assests/Homepageimages/slider1.jpg"); */
    /* height: 100vh; */
    /* height: 40rem; */
    /* margin-top: 10rem; */
    /* background-position: left; */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw ;
  }
  
  .home-banner-section::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    /* height: 100vh; */
    background-color: black;
    opacity: 0.5;
  }
  
  .home-banner-section .grid {
    position: relative;
    z-index: 2;
  }
  
.imageSize{
width: 100%;
height: 250px;
}

  @media (max-width: 768px){
    .home-banner-section {
      height:auto;
      padding-bottom: 20px;
      background: #fff;
  }
  } 
  
#time::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}

#date::-webkit-calendar-picker-indicator {
  filter: invert(1); 
  cursor: pointer;
}

