.banner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #9164f2; /* Adjust as per your needs */
  padding: 20px;
  color: white;
}
.store-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the image and the button */
}

.play-badge {
  width: 150px; /* Adjust the width as needed */
}

.download-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the button */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Rounded corners */
  font-weight: bold; /* Bold text */
  display: inline-flex; /* Align text center */
  align-items: center; /* Center vertically */
}

.download-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.banner-content {
  max-width: 50%;
}

.banner-content h1 {
  font-size: 2em;
  margin: 0;
}

.banner-content p {
  margin: 10px 0;
}

.store-buttons {
  margin-top: 20px;
}

.store-buttons img {
  margin-right: 10px;
  width: 150px; /* Adjust the width as necessary */
}

.banner-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .banner-container {
    flex-direction: column;
    text-align: center;
  }
  
  .banner-content, .banner-image {
    max-width: 100%;
  }
  
  .store-buttons img {
    width: 120px; /* Adjust for smaller screens */
  }
}
