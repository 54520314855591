/* AddFund.css */

.container {
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  tr:hover {
    background-color: #f5f5f5;
    color:black;
  }
  
  form {
    margin-bottom: 20px;
  }
  
  label {
    margin-right: 10px;
  }
  
  input[type="text"],
  input[type="number"],
  select {
    padding: 8px;
    width: 100%;
    margin-bottom: 10px;
  }
  
  button {
    padding: 8px 12px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  /* Bookings.css */
.booking-details-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .booking-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .booking-table th,
  .booking-table td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .booking-table th {
    background-color: #f2f2f2;
  }
  
  .booking-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .booking-form {
    margin-top: 20px;
  }
  
  .booking-form label {
    display: block;
    margin-bottom: 5px;
  }
  
  .booking-form select {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .booking-form button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .booking-form button:hover {
    background-color: #45a049;
  }
  /* Bookings.css */
.booking-details-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .booking-table {
    width: 100%;
    border-collapse: collapse;
    
  }  