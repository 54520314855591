.input-container {
  display: flex;
  flex-direction: column;
}

/* .input-field-container {
  position: relative;
} */

.input-field {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  color: white;
  background-color: rgb(80, 79, 79);
  border: 3px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.suggestions {
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  background:white;
  color:black;
}

.suggestions li {
  padding: 8px;
  cursor: pointer;
}

.suggestions li:hover {
  background-color: #696969;
}

@media (min-width: 1023px) and (max-width: 1300px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .form-car-resize{
    /* padding: 5px; */
  }
}

@media (min-width: 768px
) and (max-width: 1022px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  .form-car-resize{
    /* width: 100%; */
  }
  .main-container{
    margin: 0 auto;
    /* width: 100%; */
    margin-top: 0px;
    margin-bottom: 100px;

  }
  
}

@media (min-width: 500px
) and (max-width: 768px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  /* .form-car-resize{
    width: 100px;
  } */


  
  .main-container{
    margin: 0 auto;
    /* width: 75%; */
    /* margin-top: -102px; */
  }
  .img-container{
    padding: 0 auto;
  }
  
}
@media (min-width: 300px
) and (max-width: 500px) {
  /* Styles specific to small devices (landscape phones, small tablets) */
  /* .form-car-resize{
    width: 100px;
  } */
  .main-container{
    margin: 0 auto;
    padding:0;
    /* width: 80%; */
    /* margin-top: -102px; */

  }
  



}


input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0); /* This will make the date picker icon visible */
}

/* Customize the buttons inside the date picker modal (if applicable) */
input[type="date"]::-webkit-clear-button, 
input[type="date"]::-webkit-inner-spin-button {
  display: none; /* To hide clear or spinner buttons if needed */
}

/* For buttons like "Set", "Clear", and "Cancel" in the date picker modal */
.date-picker-button {
  color: black; /* Adjust the text color for buttons */
  background-color: #f0f0f0; /* Ensure background contrast */
}

.date-picker-button:hover {
  background-color: #e0e0e0; /* Darker background on hover for visibility */
}
